var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "router-link",
        {
          attrs: {
            id: "goBack",
            flat: "",
            to: { name: "admin", params: { tab: "Relationship Types" } }
          }
        },
        [
          _c(
            "v-btn",
            { attrs: { text: "", small: "", color: "primary" } },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-chevron-left")
              ]),
              _vm._v("Go Back ")
            ],
            1
          )
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Relationship Edit")]),
          _c("v-card-subtitle", [
            _vm._v("Define how one party relates to another party")
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-row",
                    { attrs: { align: "start", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: "From Relationship",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.data_types,
                              color: "primary"
                            },
                            model: {
                              value: _vm.relationship.from_rel,
                              callback: function($$v) {
                                _vm.$set(_vm.relationship, "from_rel", $$v)
                              },
                              expression: "relationship.from_rel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: "To Relationship",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.data_types,
                              color: "primary"
                            },
                            model: {
                              value: _vm.relationship.to_rel,
                              callback: function($$v) {
                                _vm.$set(_vm.relationship, "to_rel", $$v)
                              },
                              expression: "relationship.to_rel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              attrs: { color: "success", id: "saveBtn" },
                              on: { click: _vm.saveRelation }
                            },
                            [_vm._v(" Save ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }