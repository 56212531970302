<template>
  <v-container fluid>
    <router-link
      id="goBack"
      flat
      :to="{ name: 'admin', params: { tab: 'Relationship Types' } }">
      <v-btn text small color="primary">
        <v-icon color="primary">mdi-chevron-left</v-icon>Go Back
      </v-btn>
      </router-link>
      <br><br>
    <v-card>
      <v-card-title>Relationship Edit</v-card-title>
      <v-card-subtitle>Define how one party relates to another party</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-form>
          <v-row align="start" dense>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                  dense
                  outlined
                  label="From Relationship"
                  item-text="name"
                  item-value="id"
                  :items="data_types"
                  v-model="relationship.from_rel"
                  color='primary'
                >
                </v-combobox>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-combobox
                  dense
                  outlined
                  label="To Relationship"
                  item-text="name"
                  item-value="id"
                  :items="data_types"
                  v-model="relationship.to_rel"
                  color='primary'
                >
                </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
              color="success"
              class="mr-4"
              id="saveBtn"
              @click="saveRelation"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import relationshipType from '@/axios/party-relationship-type-endpoint'
import KVApi from '@/axios/key-values-endpoint'

import { cloneDeep } from 'lodash'

import { displayAlert } from '@/mixins/alert'

export default {
  name: 'relationshipTypeEdit',
  data () {
    return {
      crud: 'create',
      data_types: [],
      party_types: [],
      relationship: {
        id: '',
        from_rel: {
          id: '',
          key_id: '',
          constant: '',
          name: '',
          display_order: 0,
          created_by: '',
          updated_by: '',
          created_on: '',
          updated_on: ''
        },
        to_rel: {
          id: '',
          key_id: '',
          constant: '',
          name: '',
          display_order: 0,
          created_by: '',
          updated_by: '',
          created_on: '',
          updated_on: ''
        },
        created_by: '',
        updated_by: '',
        created_on: '',
        updated_on: ''
      }
    }
  },
  mixins: [displayAlert],
  async created () {
    await this.initDataTypes()
    if (this.$route?.query?.id) {
      this.crud = 'update'
      await this.initRelationship()
    }
  },
  methods: {
    async initRelationship () {
      await relationshipType.get(this.$route.query.id)
        .then(response => {
          this.relationship = response.data
        }).catch(err => {
          this.handleError(err)
        })
    },
    async initDataTypes () {
      await KVApi.keyValues.getList('RELATION_TYPE', 50, 0)
        .then(response => {
          this.data_types = response.data
        }).catch(err => {
          this.handleError(err)
        })
    },
    async saveRelation () {
      const saveData = cloneDeep(this.relationship)
      saveData.from_rel_id = cloneDeep(this.relationship.from_rel.id)
      saveData.to_rel_id = cloneDeep(this.relationship.to_rel.id)

      delete saveData.from_rel
      delete saveData.to_rel
      delete saveData.created_on
      delete saveData.updated_on

      if (this.valid(['from_rel_id', 'to_rel_id'], saveData)) {
        if (this.crud === 'create') {
          saveData.created_by = this.$store.getters.email
          await this.saveNew(saveData)
        } else {
          saveData.updated_by = this.$store.getters.email
          await this.updateRelationshipType(saveData)
        }
      } else {
        this.emitAlert(true, 'warning', 'Please check your form for errors')
      }
    },
    async saveNew (saveData) {
      delete saveData.updated_by
      await relationshipType.post(saveData).then(() => {
        this.emitAlert(true, 'success', 'Your party relationship has been successfully created!')
      }).catch(err => {
        this.handleError(err)
      })
    },
    async updateRelationshipType (saveData) {
      delete saveData.created_by
      await relationshipType.put(saveData.id, saveData).then(() => {
        this.emitAlert(true, 'success', 'Your party relationship has been successfully updated!')
      }).catch(err => {
        this.handleError(err)
      })
    },
    valid (fields, data) {
      this.errors.length = 0
      let returnValue = true
      fields.forEach(field => {
        if (data[field] === '') {
          this.errors.push(field)
          returnValue = false
        }
      })
      return returnValue
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.errorItem {
  padding-left: 1.5rem;
}
h5 {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
}
.createNew {
  font-style: italic;
  font-size: .8rem;
  text-align: right;
  margin-bottom: .5rem;
}
</style>
